import { http } from '@/util/http';
// url 바뀔 예정
async function login(data) {
  try {
    const result = await http.post('/enders/api/login', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

export default {
  login
}
