<template>
  <div id="wrap" class="admin">
    <!-- login// -->
    <div id="login">
      <form>
        <fieldset>
          <legend>로그인</legend>
          <section class="login-inner">
            <h1>
              <!-- 로고 클릭 시 자사 웹사이트 홈으로 -->
              <a href="javascript:;">
                <img :src="require('@/assets/img/common/logo.svg')" class="logo" alt="엔더스 로고" />
              </a>
            </h1>
            <div class="form-box">
              <input type="text" v-model="loginInfo.id" placeholder="아이디" />
              <input type="password" v-model="loginInfo.password" placeholder="비밀번호" />
              <!-- 입력한 값이 틀렸을 때 노출// -->
              <p class="error" v-if="isError">입력한 정보가 일치하지 않습니다. 다시 입력해주세요</p>
              <!-- //입력한 값이 틀렸을 때 노출 -->
              <button class="btn fullblue login" @click.prevent="login">로그인</button>
              <p class="gray_txt">*비번변경 등 문의사항은 관리자에게 문의바랍니다.</p>
            </div>
          </section>
        </fieldset>
      </form>
    </div>
    <!-- // login -->
  </div>
</template>
<script>
import loginApi from '@/apis/login';

export default {
  name: 'Login',
  data(){
    return {
      loginInfo: {
        id: '',
        password: ''
      },
      isError: false
    }
  },
  methods:{
    async login(){
      if(!this.loginInfo.id || !this.loginInfo.password){
        this.isError = true
        return
      }
      const result = await loginApi.login(this.loginInfo);
      if(result.code === 500){
        this.isError = true
      }else{
        this.$store.dispatch('login', result.result);
        this.$router.push('/admin/inquryConsultation')
      }
    }
  }
};
</script>

<style></style>
